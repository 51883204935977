export interface AuthenticateResponse {
  expiresIn: number;
  tokenType: string;
  tokenValue: string;
}

export enum AuthMethod {
  Microsoft = 'Microsoft',
  IdentityService = 'IdentityService',
}

export enum Role {
  Advisor = 'Advisor',
  NTEmployee = 'NTEmployee',
}

export interface User {
  authMethod?: AuthMethod;
  accessToken?: string;
  accessTokenExpiration?: number;
  authorized?: boolean;
  roles?: Role[];
  termsAndConditionsAcceptedDate?: string;
}
