import { toast } from 'react-toastify';
import { loginRequest, msalInstance, scopes } from 'authConfig';
import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';
import { AuthMethod, User } from 'context/AuthProvider/types';

const axiosInstance = axios.create({
  baseURL: '/api',
});

function isPassed(unixTimeSeconds?: number): boolean {
  if (!unixTimeSeconds) {
    return false;
  }
  const unixTimeMilliSeconds = unixTimeSeconds * 1000;
  return unixTimeMilliSeconds < Date.now();
}

axiosInstance.interceptors.request.use(
  async (config) => {
    const state: { user?: User } = JSON.parse(
      localStorage.getItem('state') || '{}',
    );
    if (state?.user?.authMethod === AuthMethod.Microsoft) {
      const account = msalInstance.getActiveAccount();
      if (!account) {
        throw Error(
          'No active account! Verify a user has been signed in and setActiveAccount has been called.',
        );
      }

      return msalInstance
        .acquireTokenSilent({
          scopes: scopes,
          account: account,
        })
        .then((response) => {
          config.headers.set({
            ...config.headers,
            Authorization: `Bearer ${response.accessToken}`,
          });
          return config;
        })
        .catch((error) => {
          msalInstance.logoutRedirect();
          return error;
        });
    } else if (state?.user?.authMethod === AuthMethod.IdentityService) {
      if (isPassed(state.user.accessTokenExpiration)) {
        localStorage.removeItem('state');
        window.location.reload();
        return;
      }

      const token = state.user.accessToken;
      if (!config.headers.Authorization && token) {
        config.headers.set({
          ...config.headers,
          Authorization: `Bearer ${token}`,
        });
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (axios.isCancel(error)) {
      console.warn(error);
      return { data: undefined };
    }
    let serverMessage;
    let serverDetails;
    if (Array.isArray(error?.response?.data)) {
      serverMessage = error.response.data
        .map((err: any) => err.message + '\n\n')
        .join(' ');
      serverDetails = error.response.data
        .map((err: any) => err.details)
        .join(' ');
    } else {
      serverMessage = error?.response?.data?.message;
      serverDetails = error?.response?.data?.details;
    }

    const message =
      serverMessage ||
      `Unknown error has occurred. 
      Please contact us.`;

    if (error?.response?.status === 401) {
      const state = JSON.parse(localStorage.getItem('state') || '{}');
      if (state?.user?.authMethod === AuthMethod.Microsoft) {
        msalInstance.loginRedirect(loginRequest);
      } else if (state?.user?.authMethod === AuthMethod.IdentityService) {
        localStorage.removeItem('state');
        window.location.reload();
      }
    }

    // do not show error if response is cancelled (.eg. on fast tabs switching)
    if (!axios.isCancel(error) && error?.response) {
      toast.error(message, {
        autoClose: false,
      });
    }
    if (serverDetails) {
      console.error(`API response error serverDetails: ${serverDetails}`);
    }
    return Promise.reject(error);
  },
);

export const useAxios = makeUseAxios({
  axios: axiosInstance,
});
